import "./Home.css";
import notaDeReal1 from "../images/notasdereal1.png";
import React from "react";

const Home = () => {
  return (
    <>
      <div id="PartOneMobile">
        <div id="notaDeRealDiv">
          <img src={notaDeReal1} alt="notasdereal1.png" id="notaDeReal1"></img>
        </div>

        <div className="contentOneMobile">
          <div className="texts">
            <div className="textOne">TRANSFORME AS</div>
            <div className="textTwo">FINANÇAS</div>
            <div className="same-line">
              DE SUA <span>EMPRESA</span>
            </div>

            <div className="textFour">COM UMA PLANILHA</div>
            <button>Como Funciona?</button>
            <div className="images">
                
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
